<div class="title">{{ titleText() }}</div>
<sm-search [value]="searchText()"
           [minimumChars]="0"
           [debounceTime]="0"
           placeholder="Search parameters"
           data-id="searchParameterField"
           class="underline-search"
           (valueChanged)="searchQ($event)"></sm-search>
<div class="actions">
  @if (selectedItemsList()?.length > 0) {
    <a href="" data-id="Clear Selection" class="pointer" (click)="$event.preventDefault(); onClearSelection()">Clear Selection</a>
  }
  <ng-content></ng-content>

</div>
@if (itemsList() && Object.keys(itemsList())?.length === 0) {
  <div class="empty-state">No data to show</div>
}
<div class="list">
  @for (category of (itemsObjectListFiltered() | keyvalue: compareByKey); track category.key) {
    <mat-expansion-panel [expanded]="expanded()[category.key]">
      <mat-expansion-panel-header expandedHeight="36px" collapsedHeight="36px" (click)="toggleExpand(category.key)">
        <div class="ellipsis item-key" [smTooltip]="renameMap[category.key] || category.key" [matTooltipShowDelay]="500" smShowTooltipIfEllipsis>
          {{ renameMap[category.key] || category.key }}
        </div>
        @if ((category.value | filterOut: 'checked': false).length > 0) {
          <span class="has-selected"></span>
        }
      </mat-expansion-panel-header>
      <ng-template matExpansionPanelContent>
        @for (item of category.value; track item.value) {
          <div class="item pointer"
               [class.disabled]="selectionLimitReached() && !item.checked"
               [smTooltip]="!item.checked && selectionLimitReached()? 'You can only select up to ' + limitSelection() + ' parameters' : ''"
               customClass="parameter-tooltip"
               (click)="(!selectionLimitReached() || item.checked) && toggleParamToDisplay(category.key, item.value, item.checked)">
            <mat-checkbox smClickStopPropagation [checked]="item.checked" (change)="(!selectionLimitReached() || item.checked) && toggleParamToDisplay(category.key, item.value, item.checked)">
              <div class="ellipsis" [smTooltip]="item.value" smShowTooltipIfEllipsis>{{ item.value }}</div>
            </mat-checkbox>
          </div>
        }
      </ng-template>
    </mat-expansion-panel>
  }
</div>
