<div class="x-axis-options" [class.vertical-layout]="verticalLayout" [class.short-mode]="shortMode && !verticalLayout" data-id="scalarSettings">
  <div class="d-flex field justify-content-between" [class.w-100]="verticalLayout">
    <span class="label-text axis-text-margin-left" data-id="GroupBytext">Group by</span>
    <mat-form-field appearance="outline" class="group-by-field no-bottom thin" data-id="GroupByField">
      <mat-select [value]="groupBy" (selectionChange)="groupByChanged($event)">
        @for (type of groupByOptions; track type) {
          <mat-option [value]="type.value">{{ type.name }}</mat-option>
        }
      </mat-select>
    </mat-form-field>
  </div>
  <div class="d-flex field justify-content-between" [class.w-100]="verticalLayout">
    <span class="label-text axis-text-margin-left" data-id="HorizontalAxisText">Horizontal Axis</span>
    <mat-form-field appearance="outline" class="axis-type-field no-bottom thin" data-id="HorizontalAxisField">
      <mat-select [value]="xAxisType" (selectionChange)="xAxisTypeChanged($event)" panelClass="light-theme"
                  name="scriptType">
        @for (type of xAxisTypeOption; track type) {
          <mat-option [value]="type.value">{{ type.name }}</mat-option>
        }
      </mat-select>
    </mat-form-field>
  </div>
  <div class="d-flex smooth-options">
    <div class="d-flex field">
      <span class="label-text smoothing-text" data-id="SmoothingText">Smoothing</span>
      <mat-slider
        [max]="smoothType === smoothTypeEnum.exponential ? 0.999 : 100"
        [min]="smoothType === smoothTypeEnum.exponential ? 0 : 1"
        [step]="smoothType === smoothTypeEnum.exponential ? 0.001 : 1"
        #ngSlider
      >
        <input matSliderThumb
               [ngModel]="smoothWeight"
               (ngModelChange)="changeWeight.emit($any($event))"
               [disabled]="smoothType === smoothTypeEnum.any"
               #ngSliderThumb="matSliderThumb"/>
      </mat-slider>
    </div>
    <mat-form-field appearance="outline" class="smooth-input no-bottom thin" data-id="SmoothingInputField">
      <input
        matInput
        name="minCount"
        type="number"
        title=""
        [disabled]="smoothType === smoothTypeEnum.any"
        [min]="smoothType === smoothTypeEnum.exponential ? 0 : 1"
        [max]="smoothType === smoothTypeEnum.exponential ? 0.999 : 100"
        [step]="smoothType === smoothTypeEnum.exponential ? 0.05 : 1"
        [(ngModel)]="smoothWeight"
        (ngModelChange)="trimToLimits($event)"
        (blur)="smoothWeight === null && trimToLimits(-1)"
      />
    </mat-form-field>
    <mat-form-field appearance="outline" class="smooth-type no-bottom thin">
      <mat-select [panelClass]="'light-theme wider-options'" [value]="smoothType" (selectionChange)="selectSmoothType($event)">
        @for (smoothTypeOption of smoothTypeEnum | keyvalue; track smoothTypeOption) {
          <mat-option [value]="smoothTypeOption.value">
            {{ smoothTypeOption.value }}
          </mat-option>
        }
      </mat-select>
    </mat-form-field>
    @if (!verticalLayout) {
      <button mat-icon-button class="ms-3" (click)="toggleSettings.emit()" data-id="CloseScalarSetting">
        <mat-icon fontSet="al" fontIcon="al-ico-dialog-x" class="al-icon al-ico-dialog-x sm-md pointer close-settings"></mat-icon>
      </button>
    }
  </div>
</div>
