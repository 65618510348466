<sm-dialog-template [displayX]="displayX && !dialogRef.disableClose" [header]="title"
  (xClicked)="closeDialog(undefined)" [closeOnX]="false"
  iconClass="al-ico-get-link-dialog">
  <div class="d-flex">
    <div class="sub-title" [innerHTML]="(shared ? sharedSubtitle : privateSubtitle) | purify"></div>
  </div>

  @if (shared) {
    <div class="share-link-container" >
      <div class="ellipsis share-link">{{link}}</div>
      <div ngxClipboard
        smClickStopPropagation
        [cbContent]="link"
        class="copy pointer"
        (cbOnSuccess)="copyToClipboardSuccess()">
        <mat-icon fontSet="al" fontIcon="al-ico-copy-to-clipboard"></mat-icon>
      </div>
    </div>
  }
</sm-dialog-template>
<div mat-dialog-actions align="end">
  <button mat-button (click)="createLink()">{{shared ? 'Remove' : 'Create'}} link</button>
</div>
