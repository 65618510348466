<sm-dialog-template
  [iconClass]="extend ? 'al-ico-extend' : 'al-ico-clone'"
  [header]="header"
  >
  @if (!extend) {
    <div class="reference">
      A draft copy of @if (reference) {
      <b
      [smTooltip]="reference.length > 80 ? reference : undefined">{{reference.length > 80 ? (reference | slice:0:77) + '...' : reference }}</b>
    }
    will be created.
  </div>
} @else {
  <div class="reference">
    A draft copy of @if (reference) {
    <b [smTooltip]="reference.length > 80 ? reference : undefined">{{reference.length > 80 ? (reference | slice:0:77) + '...' : reference }}</b>
    } will be created,
    with its input model set to the output model of @if (reference) {
    <b>{{reference}}</b>
    }.
  </div>
}
<form #cloneForm="ngForm">
  <div class="form-container">
    <mat-form-field class="w-100"
      appearance="outline"
      (mousedown)="!isFocused(projectInputRef) && projectInput.value && projectInput.reset(); projectInputRef.blur(); projectInputRef.focus()">
      <mat-label>Project</mat-label>
      <input matInput type="text"
        autocomplete="off"
        [matAutocomplete]="auto"
        [(ngModel)]="formData.project"
        name="projectName"
        #projectInput="ngModel"
        #projectInputRef
        required
        minlength="3"
        (keydown.enter)="projectInput.control.markAsTouched()"
        smUniqueNameValidator
        [existingNames]="readOnlyProjects$ | ngrxPush">
      @if ((!isAutoCompleteOpen) && (!$any(formData.project)?.value) && formData.project && !($any(formData.project) | stringIncludedInArray: projectsNames)) {
        <span matSuffix
        class="creat-new-suffix">(Create New)</span>
      }
      @if (formData.project) {
        <button matSuffix mat-icon-button smClickStopPropagation (click)="formData.project && projectInput.reset(); projectInputRef.focus()">
          <mat-icon fontSet="al" fontIcon="al-ico-dialog-x"></mat-icon>
        </button>
      } @else {
        <mat-icon matSuffix fontSet="al" fontIcon="al-ico-search"></mat-icon>
      }
      @if (cloneForm.controls.projectName?.errors?.required) {
        <mat-error>Please provide a Project</mat-error>
      }
      @if (cloneForm.controls.projectName?.errors?.minlength) {
        <mat-error>Name should be at least 3 characters long</mat-error>
      }
      @if (cloneForm.controls.projectName?.errors?.uniqueName) {
        <mat-error>Please provide a different name as this project name is
          taken as an Example project
        </mat-error>
      }
      @if (projectInput.touched && projectInput.errors?.emptyName && !projectInput.errors?.minlength) {
        <mat-error>*Project name can't contain only spaces.</mat-error>
      }
    </mat-form-field>
    <mat-autocomplete #auto="matAutocomplete"
      class="light-theme"
      [displayWith]="displayFn"
      (opened)="setIsAutoCompleteOpen(true)"
      (closed)="setIsAutoCompleteOpen(false)"
      >
      @if (!projectInput?.errors && projects !== null && projectInput.value && !(projectInput.value | stringIncludedInArray:projectsNames)) {
        <mat-option
          class="item"
          [value]="projectInput.value"
          >"{{projectInput.value.label || projectInput.value}}" <span class="new">(Create New)</span></mat-option>
        }
        @for (project of projects; track trackByFn($index, project)) {
          <mat-option [value]="project">
            {{project.label}}
          </mat-option>
        }
        @if (projects === null) {
          <div class="p-4 pe-none">
            <mat-spinner class="m-auto" [diameter]="32" [strokeWidth]="4" color="accent"></mat-spinner>
          </div>
        }
        <sm-dots-load-more [class.hidden]="!projects || noMoreOptions" [loading]="loading" (loadMore)="loadMore()"></sm-dots-load-more>
        <mat-option disabled style="height: 0; min-height: 0;"></mat-option> <!-- Empty mat-option, so the autocomplete menu will always pop -->
      </mat-autocomplete>
      <mat-form-field class="w-100" appearance="outline">
        <mat-label>Name</mat-label>
        <input
          required
          matInput
          autocomplete="off"
          name="ExperimentName"
          [(ngModel)]="formData.name"
          />
        @if (cloneForm.controls?.ExperimentName?.errors?.required) {
          <mat-error>Please provide a name</mat-error>
        }
      </mat-form-field>
      @if (type === 'task') {
        <mat-checkbox
          name="forceParent"
          [checked]="forceParent$ | ngrxPush"
          (change)="formData.forceParent = $event.checked"
          >Set@if (reference) {
          <b [smTooltip]="reference.length > 80 ? reference : undefined"> {{reference.length > 80 ? (reference | slice:0:77) + '...' : reference }} </b>
          }<span style="white-space: nowrap">as parent</span> </mat-checkbox>
        }
        <mat-form-field class="w-100" appearance="outline">
          <mat-label>Description</mat-label>
          <textarea
            class="clone-description"
            rows="3"
            name="ExperimentComment"
            matInput
          [(ngModel)]="formData.comment"></textarea>
        </mat-form-field>

        <div class="buttons">
          <button mat-flat-button cdkFocusInitial (click)="closeDialog(true)" [disabled]="!cloneForm.valid"
            class="yes-button" data-id="CloneButton" #cloneButton>
            {{extend ? 'EXTEND' : 'CLONE'}}
          </button>
          <button mat-stroked-button (click)="closeDialog(false)" data-id="CancelButton">
            CANCEL
          </button>

        </div>
      </div>
    </form>
  </sm-dialog-template>
