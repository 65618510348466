@if (contextNavbar() !== null) {
  <mat-tab-group mat-stretch-tabs="false"
                 mat-align-tabs="center"
                 disablePagination
                 disableRipple
                 [selectedIndex]="index()"
                 (selectedTabChange)="setFeature($event)">
    @for (route of contextNavbar(); track route.header) {
      <mat-tab *smCheckPermission="route.permissionCheck" [labelClass]="'data-id-' + (route?.id ?? route.header)">
        <ng-template mat-tab-label>
          <div class="label-container" [attr.data-id]="route?.id ?? route.header">
            <div>
              <span>{{ route.header | uppercase }}</span>
              @if (route.badge) {
                <span class="trial-badge" [smTooltip]="route.badgeTooltip" [matTooltipShowDelay]="300">
                {{ route.badge }}
              </span>
              }
            </div>
            @if (archivedMode()) {
              <div>{{ route.subHeader }}</div>
            }
          </div>
        </ng-template>
      </mat-tab>
    }
  </mat-tab-group>
}
