<mat-tree [dataSource]="dataSource()" [childrenAccessor]="childrenAccessor">
<!--  CHILDREN NODES-->
  <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding [matTreeNodePaddingIndent]="24" [class]="node.parent ? 'child' : 'parent'">
    <span [class]="node.parent ? 'child-node' : 'parent-node2'"
          [class.last-child]="node.lastChild">
      <span class="ellipsis flex-grow-1"
            [class.unchecked]="node.parent ? !(node.parent + node.name | stringIncludedInArray: checkedList()) : !checkedList().includes(node.name)"
            [smTooltip]="node.displayName ?? node" [matTooltipShowDelay]="500" smShowTooltipIfEllipsis
            (click)="itemSelect.emit(node.parent + node.name)">{{ node.displayName ?? node.name }}</span>
      <i (click)="node.parent ? itemCheck.emit( {pathString: node.parent + node.name, parent: node.parent}) :
         groupCheck(node); $event.stopPropagation()"
         class=" al-icon sm"
         [class]="(node.parent ? (node.parent + node.name | stringIncludedInArray: checkedList()) : checkedList().includes(node.name)) ? checkIcon[0] : checkIcon[1]">
      </i>
    </span>
  </mat-tree-node>
  <!--  PARENTS NODES-->
  <mat-tree-node *matTreeNodeDef="let node; when: hasChild" matTreeNodePadding [cdkTreeNodeTypeaheadLabel]="node.name" class="parent">
    <span matTreeNodeToggle class="parent-node"
          [class.expanded]="tree().isExpanded(node)"
          [class.unchecked]="!(node.childrenNames | arrayIncludedInArray:checkedList())">
      <i class="al-icon al-ico-caret-right toggle-icon"></i>
      <span class="ellipsis flex-grow-1" [smTooltip]="node.displayName ?? node.name" [matTooltipShowDelay]="500" smShowTooltipIfEllipsis>{{ node.displayName ?? node.name }}</span>
    </span>
    <i (click)="groupCheck(node); $event.stopPropagation()"
       class=" al-icon sm"
       [class]="(node.childrenNames | arrayIncludedInArray:checkedList())? checkIcon[0] : checkIcon[1]">
    </i>
  </mat-tree-node>
</mat-tree>
