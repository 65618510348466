<sm-dialog-template header="ABORT CHILD TASKS" [iconClass]="'al-ico-abort-all'">
  @if (shouldBeAbortedTasks!==null) {
    <div>
      <div class="message" [ngPlural]="shouldBeAbortedTasks?.length">
        <ng-template ngPluralCase="=0">Couldn't find any child running tasks</ng-template>
        <ng-template ngPluralCase="=1">this will abort one running child task</ng-template>
        <ng-template ngPluralCase="other">This will abort all {{shouldBeAbortedTasks?.length}} running child tasks</ng-template>
      </div>
    </div>
  } @else {
    Looking for child running tasks...
  }
</sm-dialog-template>
<div mat-dialog-actions>
  <button mat-stroked-button mat-dialog-close data-id ="CancelButton">CANCEL</button>
  <button mat-flat-button
    [mat-dialog-close]="{shouldBeAbortedTasks}"
    [disabled]="!(shouldBeAbortedTasks?.length>0)"
    data-id ="AbortButton"
  >ABORT</button>
</div>
