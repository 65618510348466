<div class="d-flex justify-content-between mb-2">
  <span class="font-weight-bold">{{titleLabel()}}</span>
</div>
<sm-search
  [value]="searchTerm()"
  [minimumChars]="0"
  [debounceTime]="0"
  class="underline-search"
  [placeholder]="placeHolder()"
  (valueChanged)="onSearchTermChanged($event)"
></sm-search>
<div class="toggle-all-text">
  <span (click)="toggleHideAll()">{{checkedList()?.length === 0 ? 'Show all' : 'Hide all'}}</span>
</div>
<sm-grouped-selectable-list
  [list]="filteredList()"
  [searchTerm]="searchTerm()"
  [checkedList]="checkedList()"
  (itemSelect)="itemSelect.emit($event)"
  (itemCheck)="toggleHide($event)"
  (groupChecked)="toggleHideGroup($event)"
></sm-grouped-selectable-list>
